import React from 'react';
import * as styles from './spiner.module.scss';

function Spiner({ load, zIndex = 99 }) {
  return load ? (
    <div className={styles.wrapper} style={{ zIndex }}>
      <div className={styles.ring}>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  ) : null;
}

export default Spiner;
