import { makeAutoObservable } from 'mobx';

export default class ProjectsMap {
  mapInstance = null;
  activeFilter = null;
  typeMarkers = null;
  visibleMarkers = [];
  industries = [];
  modalState = { active: false };

  constructor() {
    makeAutoObservable(this);
  }

  resetMapStore = () => {
    this.mapInstance = null;
    this.activeFilter = null;
    this.typeMarkers = null;
    this.visibleMarkers = [];
    this.industries = [];
    this.modalState = { active: false };
  };

  setInstance = payload => {
    this.mapInstance = payload;
  };

  setActiveFilter = payload => {
    this.activeFilter = payload;
  };

  setTypeMarkers = payload => {
    this.typeMarkers = payload;
  };

  setIndustries = payload => {
    this.industries = payload;
  };

  setModalState = payload => {
    this.modalState = payload;
  };

  setVisibleMarkers = payload => {
    this.visibleMarkers = payload;
  };
}
