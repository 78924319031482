import React, { useState } from 'react';
import cn from 'classnames';
import { Link } from 'components/UI';
import SubMenu from '../SubMenu';
import { composeLeftContent } from '../utils';
import { useHistoryCallback } from 'utils/routing';

import * as styles from './MainNav.module.scss';

const ItemWrapper = ({ item, children, ...props }) => {
  if (item?.to) {
    return (
      <Link href={item.to} state={{ activeTab: item.key }} {...props}>
        {children}
      </Link>
    );
  }

  return <div {...props}>{children}</div>;
};

function MainNav({ items = [], mods = [], currentLink, isIndexPage, isHeaderFixed }) {
  const [openTab, setOpenTab] = useState();

  useHistoryCallback(setOpenTab);

  return (
    <div className={cn(styles.mainNav, ...mods.map(key => styles[key]))}>
      <div className={styles.list}>
        {items.map(item => {
          const isActive = getActiveTab(item.submenu, currentLink) || currentLink.includes(item.to);
          const isHovered = openTab === item.key;
          const itemMod = item.options?.itemMod;
          const leftContent = composeLeftContent(item);

          return (
            <div
              key={item.key}
              className={cn(
                styles.item,
                item?.submenu && styles.submenu,
                itemMod && styles[itemMod],
                isHovered && styles.showSubMenu,
              )}
              tabIndex={item.key}
              role="button"
              onTouchStart={item?.submenu ? e => setOpenTab(item.key) : undefined}
              onMouseEnter={item?.submenu ? () => setOpenTab(item.key) : undefined}
              onMouseLeave={item?.submenu ? () => setOpenTab() : undefined}
            >
              <ItemWrapper
                className={cn(
                  styles.link,
                  item?.submenu && styles.arrow,
                  item?.submenu && styles.touchDisabled,
                  !isIndexPage && isActive && styles.current,
                  isHeaderFixed && styles.fixed,
                  isIndexPage && styles.indexPage,
                )}
                item={item}
              >
                {item.key}
                {item?.submenu && (
                  <div
                    className={cn(
                      styles.arrowIcon,
                      styles.arrowRotated,
                      !isHeaderFixed && isIndexPage && styles.arrowRotatedWhite,
                    )}
                  />
                )}
              </ItemWrapper>
              {item?.submenu && (
                <SubMenu
                  active={isHovered}
                  orientation={item.options?.submenuOrientation}
                  theme={item.options?.theme}
                  itemKey={item.key}
                  parentLink={item?.parentLink}
                  leftContent={leftContent}
                  rightContent={item.submenu}
                  currentLink={currentLink}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

function getActiveTab(submenuItems, currentLink) {
  return submenuItems?.flat(2).some(item => currentLink.includes(Object.values(item)[0]));
}

export default React.memo(MainNav);
