import React from 'react';
import cn from 'classnames';
import * as styles from './main.module.scss';

export default function Main({ className, paddingDisable, background, scrollY, children }) {
  return (
    <main
      className={cn(
        className,
        styles.content,
        paddingDisable && styles[`${paddingDisable}PaddingNone`],
        background && styles[`${background}BG`],
        scrollY && styles.scrollY,
      )}
    >
      {children}
    </main>
  );
}
