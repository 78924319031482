import React, { useMemo, useState } from 'react';
import cn from 'classnames';
import { useLocation } from '@reach/router';
import { Icon, Link } from 'components/UI';
import SubMenu from '../SubMenu';
import { isRequestedFrom, useLang } from 'utils/localisation';
import { DOMAIN_URL, DOMAIN_URL_AE, DOMAIN_URL_DE, DOMAIN_URL_AZ } from 'define';
import { arabicRegion, azerRegion } from 'define/locale';
import { excludedPages } from './constants';

import * as styles from './LangSwitcher.module.scss';

function LangSwitcher({ isHeaderFixed, isIndexPage, mobileMenuVisible }) {
  const [showSubmenu, setShowSubmenu] = useState(false);
  const location = useLocation();
  const linkAttributes = `${location.pathname}${location.search}`;

  const lang = useLang();
  const langForSwitcher = lang === 'ae' ? 'ar' : lang;

  const submenuLangs = useMemo(() => {
    if (langForSwitcher === 'de') {
      return [
        [{ DE: `${DOMAIN_URL_DE}${linkAttributes}` }, { EN: `${DOMAIN_URL}${linkAttributes}` }],
      ];
    }

    if (langForSwitcher === 'az') {
      return [
        [{ AZ: `${DOMAIN_URL_AZ}${linkAttributes}` }, { EN: `${DOMAIN_URL}${linkAttributes}` }],
      ];
    }

    if (langForSwitcher === 'en' && isRequestedFrom(azerRegion)) {
      return [
        [{ EN: `${DOMAIN_URL}${linkAttributes}` }, { AZ: `${DOMAIN_URL_AZ}${linkAttributes}` }],
      ];
    }

    return [
      [{ EN: `${DOMAIN_URL}${linkAttributes}` }, { DE: `${DOMAIN_URL_DE}${linkAttributes}` }],
    ];
  }, [langForSwitcher, linkAttributes, isIndexPage]);

  const singleLangLink = useMemo(() => {
    if (langForSwitcher === 'ar') {
      return `${DOMAIN_URL}${linkAttributes}`;
    }
    if (isIndexPage && langForSwitcher === 'en' && isRequestedFrom(arabicRegion)) {
      return `${DOMAIN_URL_AE}${linkAttributes}`;
    }
    return null;
  }, [langForSwitcher, isIndexPage]);

  if (excludedPages.includes(location.pathname) || lang === 'uk') {
    return null;
  }

  return (
    <div className={styles.langSwitcher}>
      <div className={styles.list}>
        {singleLangLink ? (
          <div className={styles.item}>
            <Link key={langForSwitcher} href={singleLangLink} className={styles.linkWithoutArrow}>
              {langForSwitcher === 'ar' ? 'EN' : 'العربية'}
            </Link>
          </div>
        ) : (
          <div
            key={langForSwitcher}
            className={cn(styles.item, styles.submenu, showSubmenu && styles.showSubmenu)}
            onMouseLeave={() => setShowSubmenu(false)}
            onClick={() => setShowSubmenu(!showSubmenu)}
            tabIndex={langForSwitcher}
            role="button"
          >
            <div className={cn(styles.link, styles.arrow)}>
              {langForSwitcher.toUpperCase()}
              <Icon
                name="small-arrow"
                className={cn(
                  styles.arrowIcon,
                  styles.smallArrowIcon,
                  !isHeaderFixed && isIndexPage && !mobileMenuVisible && styles.arrowRotatedWhite,
                  styles.arrowRotated,
                )}
              />
            </div>
            <SubMenu
              rightContent={submenuLangs}
              currentLink={location.href}
              theme="langSwitcher"
              className={styles.submenu}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default LangSwitcher;
