import { useContext, useRef, useEffect, useReducer } from 'react';
import { debounce } from 'utils';
import reducer, {
  SET_SCREEN_WIDTH,
  SET_WRAPPER,
  SET_TOOLTIP,
  SET_BODY_POSITION,
  SET_TAIL_POSITION,
} from './reducer';
import { ScrollContext } from 'utils/scroll';
import { useLang } from 'utils/localisation';

export const useTooltip = (
  isOpen,
  placement,
  tailPlacement,
  closeOnScroll,
  closeHandler,
  insideModal,
) => {
  const lang = useLang();
  const scrollRoot = useContext(ScrollContext);

  const [state, dispatch] = useReducer(reducer, {
    screenWidth: null,
    wrapper: null,
    tooltip: null,
    bodyPosition: null,
    tailPosition: null,
  });

  const tooltipRef = useRef(null);
  const wrapperRef = useRef(null);

  const { screenWidth, wrapper, tooltip, bodyPosition, tailPosition } = state;

  const calculatePosition = () => {
    const globalY = insideModal ? document.getElementById('modal').scrollTop : window.scrollY;
    //----------------- utils ----------------------------------------------------------
    const topPosition = wrapper.y + globalY - tooltip.height - 16;
    // позиционируем хвостик тултипа так, что-б он всегда был по середине и над элементом
    // от которого всплывает тултип
    const setTailSmartPosition = (relatedTo = 'wrapper') => {
      const top = topPosition + tooltip.height;
      if (relatedTo === 'wrapper') {
        return {
          left: wrapper.x + wrapper.width / 2,
          top,
        };
      }
      if (relatedTo === 'tooltip') {
        return {
          left: tooltip.x + tooltip.width / 2,
          top,
        };
      }
    };

    const setPosition = (type, values) => {
      if (type === 'body') {
        dispatch({
          type: SET_BODY_POSITION,
          payload: { ...values, top: values.top || topPosition },
        });
      }
      if (type === 'tail' && tailPlacement === 'auto') {
        dispatch({ type: SET_TAIL_POSITION, payload: values });
      }
    };

    const checkOnScreenOut = side => {
      // проверяем не вылезит ли тултип за края справа экрана
      if (side === 'rightSide') {
        const rightSideOfTooltip = wrapper.x + wrapper.width / 2 + tooltip.width / 2;
        return rightSideOfTooltip >= screenWidth;
      }
      // проверяем не вылезит ли тултип за края слева экрана
      const leftSideOfTooltip = wrapper.x + wrapper.width / 2 + tooltip.width / 2 - tooltip.width;
      return leftSideOfTooltip <= 0;
    };
    //----------------------------------------------------------------------------------------
    // когда позиционируем тултип от большых елементов типа поля формы или блока.
    if (wrapper.width > tooltip.width) {
      if (placement === 'center') {
        setPosition('body', {
          left: wrapper.x - wrapper.width / 2 + tooltip.width / 2,
        });
        setPosition('tail', setTailSmartPosition('tooltip'));
      }
      if (placement === 'start') {
        setPosition('body', {
          left: wrapper.x,
        });
        setPosition('tail', setTailSmartPosition('tooltip'));
      }
      if (placement === 'end') {
        setPosition('body', {
          left: wrapper.x + wrapper.width - tooltip.width,
        });
        setPosition('tail', setTailSmartPosition('tooltip'));
      }
      return;
    }
    // когда позиционируем тултип от мелких елементов типа иконок, кнопок и т.д.

    if (placement === 'start') {
      if (lang === 'ae') {
        setPosition('body', {
          left: wrapper.x - 250,
        });
      } else {
        if (checkOnScreenOut('leftSide')) {
          setPosition('body', {
            left: wrapper.x - 15 < 10 ? 10 : wrapper.x - 15,
          });
        } else {
          setPosition('body', {
            left: wrapper.x - 20,
          });
        }
      }

      setPosition('tail', setTailSmartPosition());
      return;
    }

    // моменты когда тултип будет выходит за рамки экрана
    // в левой части экрана
    if (wrapper.x < screenWidth / 2) {
      if (checkOnScreenOut('leftSide')) {
        setPosition('body', {
          left: 10,
        });
        setPosition('tail', setTailSmartPosition());
        return;
      }
      // в правой части экрана
    } else {
      if (checkOnScreenOut('rightSide')) {
        setPosition('body', {
          right: 10,
        });
        setPosition('tail', setTailSmartPosition());
        return;
      }
    }

    // тултип внутри модального окна
    if (insideModal && !placement) {
      const defaultLeftPosition = wrapper.x - tooltip.width / 2 + wrapper.width / 2;

      if (screenWidth < 1025) {
        setPosition('body', {
          left: defaultLeftPosition > 60 ? defaultLeftPosition - 50 : defaultLeftPosition + 10,
        });
        setPosition('tail', setTailSmartPosition());
        return;
      }
      if (lang === 'ae') {
        setPosition('body', {
          left: defaultLeftPosition + 100,
        });
        setPosition('tail', setTailSmartPosition());
        return;
      }
      setPosition('body', {
        left: defaultLeftPosition,
      });
      setPosition('tail', setTailSmartPosition());
      return;
    }

    // когда тултип не будет выходить за рамки экрана

    // когда тултип прилягает к елементу (без хвостика) (используется в карточках CVб когда хотим посмотреть все технологии)
    if (placement === 'bottom-start-stick') {
      const top = wrapper.y + window.scrollY + wrapper.height;
      // проверяем вылезит ли за правую часть экрана
      if (wrapper.x + tooltip.width >= screenWidth) {
        setPosition('body', {
          right: 10,
          top,
        });
        return;
      }
      setPosition('body', {
        left: wrapper.x,
        top,
      });
      return;
    }

    setPosition('body', {
      left: wrapper.x - tooltip.width / 2 + wrapper.width / 2,
    });
    setPosition('tail', setTailSmartPosition());
  };

  useEffect(() => {
    dispatch({ type: SET_WRAPPER, payload: wrapperRef.current?.getBoundingClientRect() });
    if (isOpen) {
      dispatch({ type: SET_TOOLTIP, payload: tooltipRef.current?.getBoundingClientRect() });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && wrapper && tooltip) {
      calculatePosition();
    }
  }, [isOpen, wrapper, tooltip]);

  useEffect(() => {
    const handleWindowResize = () => {
      dispatch({ type: SET_SCREEN_WIDTH, payload: window.innerWidth });
    };
    handleWindowResize();

    const optimizedWindowResize = debounce(handleWindowResize, 100);

    window.addEventListener('resize', optimizedWindowResize);

    if (closeOnScroll) {
      scrollRoot?.addEventListener('scroll', closeHandler, { passive: true });
    }

    return () => {
      if (closeOnScroll) {
        scrollRoot?.removeEventListener('scroll', closeHandler);
      }
      window.removeEventListener('resize', optimizedWindowResize);
    };
  }, []);

  return { wrapperRef, tooltipRef, bodyPosition, tailPosition };
};
