import React from 'react';
import { Icon } from 'components/UI';
import LocalisedPhone from 'components/LocalisedPhone';

import * as styles from './Phone.module.scss';

export default function Phone({ lang }) {
  return (
    <LocalisedPhone defaultLocale={lang}>
      {phone => (
        <a href={`tel:${phone}`} className={styles.link}>
          <Icon name="phone-filled" className={styles.iconPhone} />
          {phone}
        </a>
      )}
    </LocalisedPhone>
  );
}
