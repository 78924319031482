import React, { lazy, Suspense, useEffect } from 'react';
import Header from 'components/Header';
import Footer from 'components/Footer';
import CookiesPolicy from 'components/CookiesPolicy';
import SearchParamsManager from 'utils/SearchParamsManager';
import { modal as modalStore } from 'store';

const SuccessModal = lazy(() =>
  import('components/Modals').then(({ SuccessModal }) => ({
    default: SuccessModal,
  })),
);
const SuccessfulBookingModal = lazy(() =>
  import('components/Modals').then(({ SuccessfulBookingModal }) => ({
    default: SuccessfulBookingModal,
  })),
);
const ErrorModal = lazy(() =>
  import('components/Modals').then(({ ErrorModal }) => ({
    default: ErrorModal,
  })),
);
const BookingCallModal = lazy(() =>
  import('components/Modals').then(({ BookingCallModal }) => ({
    default: BookingCallModal,
  })),
);

const ConsentModal = lazy(() =>
  import('components/Modals').then(({ ConsentModal }) => ({
    default: ConsentModal,
  })),
);

const LangNotificationModal = lazy(() =>
  import('components/Modals').then(({ LangNotificationModal }) => ({
    default: LangNotificationModal,
  })),
);

function PageLayout({ children }) {
  const callModal = () => {
    modalStore.open('bookingCallModal');
  };

  useEffect(() => {
    if (SearchParamsManager.has('bookingCall')) {
      callModal();
    }
  }, []);

  return (
    <>
      <Header />
      {children}
      <Footer />
      <Suspense fallback={null}>
        <SuccessModal />
        <SuccessfulBookingModal />
        <ErrorModal />
        <BookingCallModal />
        <ConsentModal />
        {(process.env.GATSBY_LANG === 'ae' || process.env.GATSBY_LANG === 'az') && (
          <LangNotificationModal />
        )}
      </Suspense>
      <CookiesPolicy />
    </>
  );
}

export default PageLayout;
