// extracted by mini-css-extract-plugin
export var active = "Accordion-module--active--189f2";
export var bookingCall = "Accordion-module--bookingCall--6088a";
export var collapse = "Accordion-module--collapse--a482d";
export var exhibition = "Accordion-module--exhibition--d1a1f";
export var fadeInAnimation = "Accordion-module--fadeInAnimation--6d3ee";
export var faq = "Accordion-module--faq--90614";
export var panel = "Accordion-module--panel--4d495";
export var panelContentWrapper = "Accordion-module--panelContentWrapper--568ab";
export var title = "Accordion-module--title--45762";
export var withArrow = "Accordion-module--withArrow--ed50d";
export var wrapper = "Accordion-module--wrapper--ac6d8";