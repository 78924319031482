class SearchParamsManager {
  _search = () => new URLSearchParams(window.location.search);

  _strip = str => {
    return str ? `${window.location.pathname}?${str}` : window.location.pathname;
  };

  _paramsToObject = urlObj => {
    const result = {};
    for (const [key, value] of urlObj.entries()) {
      result[key] = value;
    }
    return result;
  };

  get(param) {
    const search = this._search();

    if (search.has(param)) {
      const params = search.get(param);

      return params ? params : '';
    }

    return '';
  }

  has(param) {
    return this._search().has(param);
  }

  async set(searchObject) {
    const search = this._search();

    for (const [key, value] of Object.entries(searchObject)) {
      if (!value || !value?.length) {
        search.delete(key);
      } else {
        search.set(key, value);
      }
    }

    window.history.replaceState(
      { path: this._strip(search.toString()) },
      '',
      this._strip(search.toString()),
    );
  }
}

const searchParamsManager = new SearchParamsManager();

export default searchParamsManager;
