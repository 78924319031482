// extracted by mini-css-extract-plugin
export var active = "Hint-module--active--728ee";
export var background = "Hint-module--background--9403c";
export var bold = "Hint-module--bold--f806b";
export var close = "Hint-module--close--13b62";
export var content = "Hint-module--content--a0e2d";
export var fadeInAnimation = "Hint-module--fadeInAnimation--f4871";
export var fixed = "Hint-module--fixed--e6e21";
export var icon = "Hint-module--icon--0bfd7";
export var iconWrapper = "Hint-module--iconWrapper--1a984";
export var modalHint = "Hint-module--modalHint--c6cf6";
export var text = "Hint-module--text--5508c";
export var text_fullWidth = "Hint-module--text_full-width--2a5ed";
export var white = "Hint-module--white--f2feb";