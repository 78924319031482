import React, { useRef, useState, useEffect } from 'react';
import cn from 'classnames';
import { useTrans } from 'utils/localisation';
import { Icon, Hint } from 'components/UI';
import { useClickOutside } from 'utils/hooks';

import * as styles from './SelectInput.module.scss';

export default function SelectInput({
  field,
  className,
  placeholder,
  selectedPlaceholder,
  options,
  value,
  onChange,
  withSearch,
  name,
  isErrorActive,
  prependedTooltip,
  inModal,
  regex = /[^a-zA-Zа-яА-Я\sёґєії-]/,
}) {
  const t = useTrans(['common']);
  const selected = value || field?.value;
  const selectRef = useRef(null);
  const inputRef = useRef(false);
  const inputFocusRef = useRef(false);
  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [scrollbarHide, setScrollbarHide] = useState(false);

  useClickOutside([selectRef], () => setIsOpen(false));

  useEffect(() => {
    if (!selected) {
      setSearch('');
    }
  }, [selected]);

  const getPlaceholder = () => {
    if (selectedPlaceholder && selected) {
      return selectedPlaceholder;
    }
    return placeholder;
  };

  const getLabel = () => {
    return options.reduce((acc, cur) => {
      if (selected === cur.value) {
        return cur.content;
      }

      return acc;
    }, '');
  };

  const inputOnChange = e => {
    const value = e.target.value;
    onChange(value);
    setSearch(options.some(el => el.value === value) ? '' : value);
  };

  const inputOnKeydown = e => {
    if (e.key.length === 1 && regex.test(e.key)) {
      e.preventDefault();
    }
  };

  const toggle = () => {
    setIsOpen(!isOpen);
    return !isOpen;
  };

  const headClickHandler = () => {
    if (inputFocusRef.current && isOpen) return;

    toggle();
    setScrollbarHide(true);
  };

  const handleOptionClick = clicked => {
    if (clicked.value === selected) {
      setIsOpen(false);
      return;
    }

    if (withSearch) {
      const value = clicked.value ?? inputRef.current.value;
      onChange(value);
      setSearch(options.some(el => el.value === value) ? '' : value);
    } else {
      onChange(clicked.value);
    }

    setIsOpen(false);
  };

  const listContent = () => {
    let filteredOptions = options;
    if (search) {
      filteredOptions = options.filter(({ content }) =>
        content.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (filteredOptions?.length > 0) {
      return filteredOptions.map(option => (
        <div
          key={option.value}
          className={cn(styles.option, selected === option.value && styles.optionSelected)}
          onClick={() => handleOptionClick(option)}
          data-active={selected === option.value}
        >
          {option.content}
        </div>
      ));
    }

    if (search) {
      return <div className={styles.emptySearchResult}>{t('notFound')}</div>;
    }

    return <div className={styles.emptySearchResult}>{t('emptyOptions')}</div>;
  };

  const onTransitionEnd = () => {
    setScrollbarHide(false);
  };

  return (
    <div
      className={cn(className, styles.select, isErrorActive && styles.error)}
      ref={selectRef}
      tabIndex={0}
      name={name}
    >
      {prependedTooltip && (
        <div className={styles.tooltipWrapper}>
          <Hint
            withIcon
            hintInsideModal={inModal}
            className={styles.prependedTooltip}
            closeOnBackground={false}
            content={prependedTooltip}
            placement="start"
            theme="modalHint"
            closeOnScroll
          />
        </div>
      )}
      <button
        onClick={headClickHandler}
        className={cn(
          styles.button,
          selected?.length > 0 && styles.selected,
          isOpen && styles.opened,
          prependedTooltip && styles.withPrependedTooltip,
        )}
        type="button"
      >
        {withSearch ? (
          <input
            className={styles.input}
            onFocus={() => (inputFocusRef.current = true)}
            onBlur={() => (inputFocusRef.current = false)}
            placeholder={placeholder}
            value={selected}
            onKeyDown={inputOnKeydown}
            onChange={inputOnChange}
            type="text"
          />
        ) : (
          <div className={styles.title}>{getLabel()}</div>
        )}
        <span className={styles.placeholder}>{getPlaceholder()}</span>
        <div className={styles.arrow}>
          <Icon className={styles.arrowIcon} name="arrow" />
        </div>
      </button>
      <div
        className={cn(styles.dropdown, scrollbarHide && styles.scrollbarHide)}
        onTransitionEnd={onTransitionEnd}
      >
        {listContent()}
      </div>
    </div>
  );
}
