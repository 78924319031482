export const pushCustomSubmitEvent = formID =>
  window.dataLayer.push({
    event: 'custom_submit_true',
    category: 'custom_submit_true',
    'gtm.elementId': `url: ${window.location.href}; formID: ${formID};`,
  });

export const pushABTestEvent = (testCategory, testAction, testLabel) =>
  window.dataLayer.push({
    event: `ab_test_${testCategory}`,
    category: `ab_test_${testCategory}`,
    action: `ab_test_${testAction}`,
    label: `ab_test_${testLabel}`,
  });

export const pushCookiePolicyEvent = () =>
  window.dataLayer.push({
    event: 'cookie_policy_update',
  });

export const pushCustomEvent = (event, options = {}) => {
  window.dataLayer.push({
    event,
    ...options,
  });
};
