// extracted by mini-css-extract-plugin
export var active = "SubMenu-module--active--877c5";
export var arrow = "SubMenu-module--arrow--9b59e";
export var borderBold = "SubMenu-module--borderBold--2c67f";
export var button = "SubMenu-module--button--f599e";
export var clickableArea = "SubMenu-module--clickableArea--fae6d";
export var column = "SubMenu-module--column--2a2c6";
export var description = "SubMenu-module--description--109af";
export var fadeInAnimation = "SubMenu-module--fadeInAnimation--8e9e7";
export var firstLink = "SubMenu-module--firstLink--b27f0";
export var image = "SubMenu-module--image--43714";
export var item = "SubMenu-module--item--94444";
export var langSwitcher = "SubMenu-module--langSwitcher--1e9d0";
export var left = "SubMenu-module--left--479bc";
export var link = "SubMenu-module--link--6b5df";
export var list = "SubMenu-module--list--17e8e";
export var listItem = "SubMenu-module--listItem--2f531";
export var owlSoul = "SubMenu-module--owlSoul--2100c";
export var parentLink = "SubMenu-module--parentLink--eda9c";
export var picture = "SubMenu-module--picture--c2dc5";
export var right = "SubMenu-module--right--2d528";
export var rightElementsWrap = "SubMenu-module--rightElementsWrap--785ca";
export var scroll = "SubMenu-module--scroll--e926b";
export var subMenu = "SubMenu-module--subMenu--b84ba";
export var sublinks = "SubMenu-module--sublinks--9f60c";
export var title = "SubMenu-module--title--f0215";
export var topfixed = "SubMenu-module--topfixed--762c6";
export var visible = "SubMenu-module--visible--8e887";
export var wrapLink = "SubMenu-module--wrapLink--a9a7d";