import React from 'react';
import cn from 'classnames';
import { Icon } from 'components/UI';
import { observer, modal as modalStore } from 'store';
import { upperFirst } from 'utils';
import { useTrans } from 'utils/localisation';

import * as styles from './BookingButton.module.scss';

function BookingButton({ className, fontSize = 'sm', theme }) {
  const t = useTrans(['bookingCall']);

  const callModal = () => {
    modalStore.open('bookingCallModal');
  };

  return (
    <button className={cn(styles.button, className, theme && styles[theme])} onClick={callModal}>
      <Icon name="phone-filled" className={styles.phoneIcon} />
      <span
        className={cn(styles.buttonTitle, fontSize && styles[`fontSize${upperFirst(fontSize)}`])}
      >
        {t(['bookCall'])}
      </span>
    </button>
  );
}

export default observer(BookingButton);
