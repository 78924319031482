import React from 'react';
import cn from 'classnames';
import Link from 'components/UI/Link';
import * as styles from './Button.module.scss';

/**
 * @description используется для унификации кнопок на сайте
 * @param {String} theme внешний вид кнопки
 * @param {String} size ширина кнопки
 * @param {Boolean} fullWidthMobile задаёт 100% ширину кнопки для мобильных экранов
 * @param {Boolean} disabled вид кнопки при disabled
 * @param {Node} children наследники
 * @param {String} className уникальные стили
 * @param {String} tag используемый тег
 * @param {Array} other другие props
 */
function Button({
  theme = 'primary',
  size = 'md',
  fullWidthMobile,
  disabled,
  children,
  className,
  tag = 'button',
  ...other
}) {
  const Wrapper = tag === 'a' ? Link : tag;
  return (
    <Wrapper
      className={cn(
        styles.button,
        theme && styles[theme],
        size && styles[size],
        fullWidthMobile && styles.fullWidthMobile,
        disabled && styles.disabled,
        className,
      )}
      disabled={disabled}
      {...other}
    >
      {children}
    </Wrapper>
  );
}

export default Button;
