// extracted by mini-css-extract-plugin
export var bannerHide = "Header-module--bannerHide--04f21";
export var bookingButton = "Header-module--bookingButton--08bb2";
export var button = "Header-module--button--2246f";
export var buttonTransparent = "Header-module--buttonTransparent--eee99";
export var communicationWrapper = "Header-module--communicationWrapper--0f5ce";
export var fadeInAnimation = "Header-module--fadeInAnimation--37baf";
export var fixed = "Header-module--fixed--39e53";
export var header = "Header-module--header--357cc";
export var hiddenMg = "Header-module--hiddenMg--2fbdd";
export var item = "Header-module--item--80dc2";
export var logo = "Header-module--logo--edb3a";
export var logoText = "Header-module--logoText--aa960";
export var logoWrap = "Header-module--logoWrap--e3ae4";
export var mobileHidden = "Header-module--mobileHidden--1ac1a";
export var mobilePanel = "Header-module--mobilePanel--ec3ce";
export var navigation = "Header-module--navigation--acbc9";
export var progressContainer = "Header-module--progressContainer--f7b7b";
export var progressbar = "Header-module--progressbar--9c040";
export var scrollTop = "Header-module--scrollTop--f4371";
export var separator = "Header-module--separator--364a2";
export var topLine = "Header-module--topLine--d4be9";
export var topLineLayout = "Header-module--topLineLayout--41639";
export var transparentBg = "Header-module--transparentBg--6bfa1";
export var transparentLogo = "Header-module--transparentLogo--3ed03";
export var whiteColor = "Header-module--whiteColor--71ac8";